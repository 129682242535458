import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  InputAsyncSelect,
  InputDate,
  InputMoney,
} from '~/components/NovosInputs';

import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import Separator from '~/components/Separator';

import { toast } from 'react-toastify';

import { deleteItens, insertOrUpdate } from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { ButtonItens } from '~/pages/NFOutrasOperacoes/screens/Nfe/styles';
import { FaEraser, FaSync } from 'react-icons/fa';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';
import { LojaContext } from '~/context/loja';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { differenceInDays, isBefore, parseISO } from 'date-fns';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

export const FormParcelas: React.FC = () => {
  const { codLoja } = useContext(LojaContext);
  const [numParcela, setNumParcela] = useState(1);

  const {
    formFinanceiro: {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      clearErrors,
      setError,
      formState: { errors },
    },
    formPedidoVenda: { getValues: getValuesPedidoVenda },
    isFormFinanceiroEditing,
    setIsFormFinanceiroEditing,
    isDisabledPedido,
    parcelasRows,
    setParcelasRows,
    setMasterDetail,
    masterDetail,
    handleRecalculaFinanceiro,
    valorTotal,
  } = usePedidoVenda();

  const focusInputByName = (inputName: string) => {
    const inputElement = document.querySelector<HTMLInputElement>(
      `input[name="${inputName}"]`,
    );
    if (inputElement) inputElement.focus();
  };

  useEffect(() => {
    const valorPedido = transformAsCurrency(valorTotal || 0);
    if (parcelasRows.length !== 0) {
      const totalParcelas = parcelasRows.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = valorPedido - totalParcelas;
      setTimeout(() => {
        setValue(
          'val_financeiro',
          formatCurrencyAsText(resultado > 0 ? resultado : 0),
        );
      }, 10);
    } else {
      setTimeout(() => {
        setValue('val_financeiro', formatCurrencyAsText(valorPedido));
      }, 10);
    }
  }, [parcelasRows, valorTotal]);

  const calcularDiasEntreDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): number => {
      if (!dtaVencimento || !dtaEmissao) return 0;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return differenceInDays(dataVencimento, dataEmissao);
    },
    [],
  );

  const validarDatas = useCallback(
    (dtaVencimento: string, dtaEmissao: string): boolean => {
      if (!dtaVencimento || !dtaEmissao) return false;

      const dataVencimento = parseISO(dtaVencimento);
      const dataEmissao = parseISO(dtaEmissao);

      return !isBefore(dataVencimento, dataEmissao);
    },
    [],
  );
  const formatToMonetaryValue = useCallback((input: number | string) => {
    if (typeof input === 'number') return input;

    const cleanedInput = input.replace(/[^0-9.,]/g, '');
    const standardizedInput = cleanedInput.replace(',', '.');
    const withoutLeadingZeros = standardizedInput.replace(/^0+(?!\.)/, '');
    const monetaryValue = parseFloat(withoutLeadingZeros);

    if (Number.isNaN(monetaryValue)) return 0;
    return monetaryValue;
  }, []);

  const handleAddParcela = handleSubmit(
    async (data: any) => {
      const dta_emissao = getValuesPedidoVenda('dta_emissao');
      const uuid =
        isFormFinanceiroEditing?.uuid === undefined
          ? nanoid()
          : isFormFinanceiroEditing.uuid;
      if (!validarDatas(data.dta_vencimento, dta_emissao)) {
        setError('dta_vencimento', { type: 'required' }, { shouldFocus: true });
        toast.warning(
          'A data de vencimento não pode ser anterior à data de emissão.',
        );
        return;
      }

      if (data.val_financeiro === '' || data.val_financeiro === '0,00') {
        setError('val_financeiro', { type: 'required' });
        return;
      }

      const exists = parcelasRows.some(
        (parcela) =>
          parcela.dta_vencimento === data.dta_vencimento &&
          parcela.cod_finalizadora === data.finalizadora.value &&
          parcela.uuid !== isFormFinanceiroEditing.uuid,
      );

      if (exists) {
        const { isConfirmed } = await MySwal.fire({
          title: 'Já existe uma parcela para esse dia e finalizadora.',
          text: 'Deseja editar a parcela já existente?',
          showCancelButton: true,
          confirmButtonColor: '#07289e',
          cancelButtonColor: '#ff7b7b',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
        });

        if (!isConfirmed) return;

        const itemDuplicado = parcelasRows.find(
          (item) =>
            item.cod_finalizadora === data.finalizadora.value &&
            item.dta_vencimento === data.dta_vencimento,
        );

        if (itemDuplicado) {
          itemDuplicado.val_parcela = data.val_financeiro;
        }
      }

      const formatedParcela: any = {
        uuid,
        id: uuid,
        num_condicao: calcularDiasEntreDatas(data.dta_vencimento, dta_emissao),
        cod_condicao: 2,
        des_condicao: 'DD - DIAS DA DATA',
        dta_vencimento: data.dta_vencimento,
        val_parcela: transformAsCurrency(data.val_financeiro),
        cod_finalizadora: data.finalizadora.value,
        des_finalizadora: data.finalizadora.label,
        num_registro: numParcela,
      };
      setNumParcela(numParcela + 1);

      const parcelasDetail: any[] = await insertOrUpdate(
        'financeiro',
        formatedParcela,
        masterDetail,
        setMasterDetail,
      );

      const othersParcels = parcelasRows.filter((p) => p.uuid !== uuid);

      const parcels = [...othersParcels, ...parcelasDetail];

      parcels.forEach((parcel) => {
        deleteItens('financeiro', parcel.uuid, masterDetail, setMasterDetail);
      });

      parcels.sort((a, b) => a.num_condicao - b.num_condicao);

      const seen = new Set<string>();
      const result: any[] = [];

      parcels.forEach((op) => {
        const key = [
          'cod_condicao',
          'cod_finalizadora',
          'cod_parcela',
          'des_condicao',
          'num_condicao',
        ]
          .map((k) => op[k])
          .join('|');

        if (!seen.has(key)) {
          seen.add(key);
          result.push(op);
        }
      });

      const parcelCorrigida = (res: any[]) =>
        res
          .sort(
            (a, b) =>
              new Date(a.dta_vencimento).getTime() -
              new Date(b.dta_vencimento).getTime(),
          )
          .map((p, index) => {
            const desFinalizadora = p.des_finalizadora ?? '';
            const [firstPart, ...restParts] = desFinalizadora.split(' - ');
            const lastPart = restParts.length
              ? restParts[restParts.length - 1]
              : '';
            const description = restParts.length
              ? `${firstPart} - ${lastPart}`
              : firstPart;

            return {
              ...p,
              val_parcela: formatToMonetaryValue(p.val_parcela || 0),
              des_finalizadora: description,
              num_registro: index + 1,
            };
          });

      const parcelaCorrigida = parcelCorrigida(result);

      parcelaCorrigida.forEach(async (p) => {
        await insertOrUpdate('financeiro', p, masterDetail, setMasterDetail);
      });

      setParcelasRows(parcelaCorrigida);
      reset();
    },
    (err) => {
      console.log(err);
    },
  );

  const refDivBtn = useRef<HTMLDivElement | null>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        setTimeout(() => {
          if (refDivBtn.current) {
            const btn: HTMLButtonElement | null =
              refDivBtn.current.querySelector('button[type="button"]');
            focusInputByName('dta_vencimento');
            if (btn) btn.click();
          }
        }, 100);
      }
    },
    [],
  );

  return (
    <>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens
            type="button"
            onClick={() => handleRecalculaFinanceiro()}
            disabled={isDisabledPedido}
          >
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row className="d-flex align-items-center position-relative">
        <Col sm={12} md={4}>
          <InputAsyncSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="finalizadora"
            register={register}
            isError={!!errors.finalizadora}
            control={control}
            disabled={isDisabledPedido}
            changeSelected={(selected) => {
              clearErrors('finalizadora');
              setValue('finalizadora', selected);
            }}
            api={{
              route: '/finalizadora',
              method: 'get',
              bodyParams: {
                codLoja,
              },
              fields: ['cod_finalizadora', 'des_finalizadora'],
              dependsOf: ['codLoja'],
              messageForDependsOf:
                'É necessário selecionar a loja na capa da NF',
              searchBeforeFilter: true,
            }}
            onKeyDown={handleKeyDown}
          />
        </Col>

        <Col sm={12} md={2}>
          <InputDate
            register={register}
            label="Vencimento"
            name="dta_vencimento"
            control={control}
            disabled={isDisabledPedido}
            isError={!!errors.dta_vencimento}
            onKeyDown={handleKeyDown}
          />
        </Col>
        <Col sm={12} md={2} onKeyDown={handleKeyDown}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            name="val_financeiro"
            register={register}
            control={control}
            disabled={isDisabledPedido}
            isError={!!errors.val_financeiro}
          />
        </Col>
        <Col
          md={2}
          sm={12}
          ref={refDivBtn}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={async () => {
              await handleAddParcela();
              setTimeout(() => {
                setIsFormFinanceiroEditing({ isEdit: false, uuid: undefined });
              }, 300);
            }}
            label="Adicionar"
            icon={IoMdAddCircleOutline}
            disabled={isDisabledPedido}
            variant="confirm"
          />
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <CustomButtonNovo
            onClick={() => {
              reset({
                num_condicao: 30,
                finalizadora: '',
                dta_vencimento: '',
                val_financeiro: '',
              });
              setIsFormFinanceiroEditing({ isEdit: false, uuid: undefined });
            }}
            label="Limpar"
            icon={FaEraser}
            disabled={isDisabledPedido}
            variant="clear"
          />
        </Col>
      </Row>
    </>
  );
};
